<template>
  <ion-page>
    <ion-header>
      <div class="icon-scroll mb-0" />
      <ion-toolbar class="pr-3">
        <ion-title>{{ $t('sort') }}</ion-title>
        <ion-icon slot="end" :icon="closeOutline" @click="closeModal" />
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-radio-group :value="currentFilter ? currentFilter : changeSortItemKey">
        <ion-item
          class="item"
          v-for="item in getListSortByCategory()"
          :key="item.key"
          @click="selectFilter(item.key)"
        >
          <ion-radio class="mr-2" mode="md" :value="item.key" />
          <ion-label>{{ $t(item.message) }}</ion-label>
        </ion-item>
      </ion-radio-group>
    </ion-content>
    <ion-footer class="ion-padding d-flex justify-space-between">
      <ion-button class="button" fill="outline" @click="resetFilter">{{ $t('reset_filter') }}</ion-button>
      <ion-button class="button" @click="submitFilter">{{ $t('apply_filter') }}</ion-button>
    </ion-footer>
  </ion-page>
</template>
<script>
import { GETTER, MODULES_FILTER } from '@/modules/shared/constants/';
import Clevertap from '@/services/shared/helper/clevertap';
import { closeOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import { getCategoryListSort, useModalSort } from './composable';
export default defineComponent({
  props: {
    storeAddress: {
      type: String,
      required: true,
      default: ''
    },
    closeModal: {
      required: true,
      default: () => {}
    },
    tab: {
      type: String,
      required: true,
      default: ''
    },
    currentFilter: {
      type: String,
      default: ''
    },
    clearPrevFilter: {
      required: true,
      default: () => {}
    }
  },
  setup(props) {
    const { getters } = useStore();
    const defaultSortItemKey = getters[`${MODULES_FILTER}/${GETTER.GET_FILTER}`];
    const { applyFilter, resetFilter, changeSortItemKey, categoryName } = useModalSort({
      defaultSortItemKey,
      storeAddress: props.storeAddress,
      tabCategory: props.tab,
      currentFilter: props.currentFilter
    });
    const selectFilter = (key) => {
      changeSortItemKey.value = key;
    };
    const submitFilter = async () => {
      Clevertap.onUserApplyFilterProduct(changeSortItemKey);
      props.clearPrevFilter();
      props.closeModal();
      await applyFilter();
    };
    const getListSortByCategory = () =>
      getCategoryListSort(props.tab === 'history' ? props.tab : categoryName);

    return {
      resetFilter,
      changeSortItemKey,
      getListSortByCategory,
      submitFilter,
      closeOutline,
      selectFilter
    };
  }
});
</script>
<style lang="scss" scoped>
.item {
  --inner-border-width: 0px;
}
.button {
  --padding-top: 20px;
  --padding-bottom: 20px;
  --padding-start: 36px;
  --padding-end: 36px;
  --border-radius: 10px;
  --border-width: 1px;
  text-transform: none;
}
ion-icon {
  color: black;
  font-size: 20px;
  background-color: #989aa226;
  padding: 5px;
  border-radius: 20px;
}
</style>
